import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Offcanvas,
  Form,
  Button,
  Dropdown,
} from "react-bootstrap";
import EyeIcon from "../assets/svg/EyeIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "../assets/images/logo.png";
import GamesSearchModal from "../views/components/GamesSearchModal";
import { isMobile } from "react-device-detect";
import { APP_CONST } from "../config/const";
import LoginModal from "../views/components/authModals/LoginModal";
import ModalWrapper from "./ModalWrapper";
import LanguageChange from "../views/components/LanguageChange";
import APK from "../assets/images/icons/Icon.png";
import { AiOutlineClose } from "react-icons/ai";
import CurrencyDropdown from "../views/components/CurrencyDropdown";

import HomeIcon from "../assets/images/icons/header/Home.png";
import VIPIcon from "../assets/images/icons/header/premium-quality.png";
import AffiliatesIcon from "../assets/images/icons/header/affiliate.png";
import PromotionIcon from "../assets/images/icons/header/Promotion.png";
import SupportsIcon from "../assets/images/icons/header/Support.png";
import DownloadIcon from "../assets/images/icons/header/APKDownload.png";

import LoginImgMob from "../assets/images/icons/enter.png";

import NotificationIcon from "../assets/images/icons/bell.png";
import { getNotifications } from "../redux/app/actions";

const Header = () => {
  const [visible, setVisible] = useState(true);

  const isApk = localStorage.getItem("isApk") === "true";
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);
  const [showToggle, setShowToggle] = useState(true);
  useEffect(() => {
    if (visible) {
      document.body.classList.add("download-apk");
    } else {
      document.body.classList.remove("download-apk");
    }
  }, [visible]);
  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [show1, setShow1] = useState(false);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState(APP_CONST.AUTH_MODAL.LOGIN);
  const handleShow1 = () => setShow1(!show1);

  const handleModalShow = (modalName) => {
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };
  const handleModalClose = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };

  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const headerPages = [
    "betby",
    "exchange",
    "casino",
    "qtechbetgamestv",
    "promotions",
    "blog",
    "/",
    "qtech",
  ];
  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  const formattedDate = currentDateTime.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const formattedTime = currentDateTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    // second: "numeric",
    hour12: true,
  });
  useEffect(() => {
    setShowToggle(window.screen.width > 992);
    loadNotifications();
    return () => {};
  }, []);

  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="Announcement">
          <div className="latest">
            <img src={NotificationIcon} alt="NotificationIcon" />
          </div>
          <marquee>
            {" "}
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }}>{notf?.content}</label>
            ))}
          </marquee>
        </div>
      )) ||
        null}
      <header className="header beforeheader">
        <div className="container-fluid">
          <div className="top_head">
            <div className="d-flex justify-content-between align-items-center">
              <div className="head-tabs ">
                <ul className="d-md-flex d-none">
                  <li>
                    <a
                      href="/"
                      className={activePage == "home" ? "active" : ""}
                    >
                      <img src={HomeIcon} alt="HomeIcon" />
                      <span>Home</span>
                    </a>
                  </li>
                  <li>
                    <a href="" className={activePage == "" ? "active" : ""}>
                      <img src={PromotionIcon} alt="PromotionIcon" />
                      <span>Prmotions</span>
                    </a>
                  </li>
                  <li>
                    <a href="" className={activePage == "" ? "active" : ""}>
                      <img src={VIPIcon} alt="VIPIcon" />
                      <span>Kingmaker</span>
                    </a>
                  </li>
                  <li>
                    <a href="" className={activePage == "" ? "active" : ""}>
                      <img src={AffiliatesIcon} alt="VIPIcon" />
                      <span>Affiliates</span>
                    </a>
                  </li>
                  <li>
                    <a href="" className={activePage == "" ? "active" : ""}>
                      <img src={SupportsIcon} alt="VIPIcon" />
                      <span>Support</span>
                    </a>
                  </li>
                  <li>
                    <a href="" className={activePage == "" ? "active" : ""}>
                      <img src={DownloadIcon} alt="VIPIcon" />
                      <span>App download</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="logo">
                <a href="/">
                  <img src={appDetails?.LOGO_URL} alt="Logo" />
                  {/* <img src={logo} alt="Logo" /> */}
                </a>
              </div>

              <div className="headerRight">
                <Button
                  variant="primary"
                  onClick={() => {
                    // !isMobile
                    // ?
                    handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                    // : navigate("/sign-in");
                  }}
                  className="button-primary btn_signin"
                >
                  <span className="d-none d-md-block">Log In</span>
                  <span className="d-md-none LoginMob">
                    <img src={LoginImgMob} alt="" />
                    Login
                  </span>
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    // !isMobile
                    // ?
                    handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
                    // : navigate("/sign-in");
                  }}
                  className="button-primary btn_signup btn-secondary ms-3 d-md-block d-none"
                >
                  Sign Up
                </Button>
              </div>
            </div>
          </div>
        </div>

        {showAuthModals && (
          <ModalWrapper
            defaultShow={defaultModal}
            handleClose={setShowAuthModals}
          />
        )}
      </header>
    </>
  );
};

export default Header;
